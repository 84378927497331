import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "timeago"
    }}>{`TimeAgo`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`TimeAgo`}</inlineCode>{` component renders a block of text showing how long ago the provided timestamp was
in a human-readable manner. For example "8 seconds ago". It also allows you to hover over the
block of text to see the full date and time, "December 11 2020 11:00 AM".`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <pre><code parentName="pre" {...{}}>{`* timestamp -> number | string = A timestamp either in epoch numeral or string format.
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { TimeAgo } from '@kineticdata/bundle-common';

<TimeAgo timestamp={space.createdAt} />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      